import Layout from '../components/layout';
import * as React from 'react';
import {graphql, Link} from 'gatsby';
import {Card, Col, Container, Row} from 'react-bootstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from '../components/seo';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import documentToReactOptions from '../shared-options';
import {LayoutHeadingProps} from '../components/layout-heading';

function fullName(person) {
    return [person.title, person.name].join(' ').trim();
}

function rankRow(rank) {

    const isPrincipal = rank.people.length === 1;

    // Show the header, unless this is the Principal.
    const header = isPrincipal ?  <h2 className='sr-only'>{rank.name}</h2> : <h2>{rank.name}s</h2>;

    const row = isPrincipal ?
      <Row>{people(rank)}</Row> : <Row xs={1} md={2} xl={3}>{people(rank)}</Row>;

    return (<div key={rank.id}>
        <div className='px-0 px-sm-3'>
            <Container fluid='xl'>{header}</Container>
        </div>
        <Container fluid='xl' className='px-xl-0'>{row}</Container>
    </div>);
}

function people(rank) {
    return <>
        {rank.people.map(person =>
          <Col className='mb-4' key={person.slug}>
              <Link to={person.slug} className='aae-card-link'>
                  <Card className='h-100 border-0 bg-transparent'>
                      <Card.Body className='d-flex flex-column'>
                          <Card.Title className='aae-action-arrow text-decoration-none d-block mb-0' as='h3'>
                              {fullName(person)}
                          </Card.Title>
                          <Row>
                              <Col className={person.shortBio ? 'mt-3' : null}>
                                  {rank.name === 'Principal' && <>
                                      <p className='lead'>{person.shortBio}</p>
                                      {documentToReactComponents(JSON.parse(person.longBio?.raw), documentToReactOptions)}
                                  </>}
                                  {rank.name !== 'Principal' && rank.showDetailOnTeamPage &&  person.shortBio}
                              </Col>
                              <Col xs={4}>
                                  {person.photo?.gatsbyImageData && rank.showDetailOnTeamPage &&
                                    <GatsbyImage
                                        image={person.photo.gatsbyImageData}
                                        alt={fullName(person)}
                                        className='mt-3' />
                                  }
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Link>
          </Col>
        )}
    </>;
}

const TeamPage = ({data}) => {

    // Load in the Ranks and order people by their lastName
    const ranks = data.allContentfulRank.ranks.filter(rank => rank.people != null);
    ranks.map(rank => rank.people.sort((a, b) => (a.lastName > b.lastName) ? 1 : -1));

    const heading: LayoutHeadingProps = {
        title: <h1>Team</h1>
    };

    return (
        <Layout heading={heading} container={false}>

            <SEO
              title='Team'
              description='Find out about our team of accessibility and technical experts.'
            />

            {ranks.map(rank => rankRow(rank))}

        </Layout>
    );
}

export default TeamPage;

export const query = graphql`
    {
        allContentfulRank(sort: {fields: order, order: ASC}) {
            ranks: nodes {
                id
                name
                showDetailOnTeamPage
                order
                people: person {
                    name
                    lastName
                    joinDate
                    slug
                    shortBio
                    title
                    longBio {
                        raw
                    }
                    photo {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        }
    }
`

