import * as React from 'react'
import {Helmet} from 'react-helmet'
import {useLocation} from '@reach/router'
import {graphql, useStaticQuery} from 'gatsby'


type SEOProps = {
  title?: string,
  description?: string,
  image?: string,
  article?: boolean,
}

export default function SEO(props: SEOProps) {
  const { pathname } = useLocation()
  const site = useStaticQuery(query).site.siteMetadata;

  const {
    defaultImage
  } = site

  // Some SEO-specific settings.
  const seo = {
    title: props.title || site.name,
    description: props.description || site.description,
    image: `${site.url}${props.image || defaultImage}`,
    url: `${site.url}${pathname}`,
  }

  return (
    <Helmet
      title={props.title}
      defaultTitle={site.name}
      titleTemplate={`%s · ${site.name}`}
    >
      <html lang='en'/>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel='canonical' href={seo.url} />

      {seo.url && <meta property="og:url" content={seo.url} />}

      {(props.article ? true : null) && <meta property="og:type" content="article" />}

      {seo.title && <meta property="og:title" content={seo.title} />}

      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}

      {seo.image && <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {/*{twitterUsername && (*/}
      {/*  <meta name="twitter:creator" content={twitterUsername} />*/}
      {/*)}*/}

      {seo.title && <meta name="twitter:title" content={seo.title} />}

      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}

      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                name
                description
                url
#                defaultImage: image
#                twitterUsername
            }
        }
    }
`
