import * as React from 'react';
import {useEffect, useState} from 'react';

type LoadStatus = 'loading' | 'success' | 'error';

type TwitterFeedProps = {
  items: number;
}

type TweetUrl = {
  url: string;
}

type Tweet = {
  id: number;
  id_str: string;
  created_at: Date;
  text: string;
  url: string;

  entities: {
    urls: TweetUrl[];
  }

  user: {
    name: string;
    screen_name: string;
    profile_image_url_https: string;
  }

  retweeted_status: Tweet;
  tweetedUrl: string;
}

const imageStyle = {
  width: 48,
  height: 48
}

const userInfoStyle = {
  fontSize: '0.9rem'
}

// Render inline SVG with fallback non-svg images
export default function TwitterFeed(props: TwitterFeedProps) {

  const tweetsEndpoint = 'https://access.ecs.soton.ac.uk/api/v1/twitter_feed.php';
  // const tweetsEndpoint = `http://localhost:8001/v1/twitter_feed.php?limit=${props.items}`;

  const [tweets, setTweets] = useState<Tweet[]>([]);
  const [loadStatus, setLoadStatus] = useState<LoadStatus>('loading');

  let isMounted = true;

  useEffect(() => {

    // Fetch each provided URL, convert the response to JSON and push new Posts to the postsRepo.
    fetch(tweetsEndpoint)
      .then(response => response.json())
      .then(tweets => {
        // Only continue if the component is still mounted
        if(isMounted) {
          setTweets(tweets.map(tweet => {
            tweet.created_at = new Date(tweet.created_at);
            [tweet.text, tweet.tweetedUrl] = tweet.text.split('…');

            if (tweet.retweeted_status) {
              [tweet.retweeted_status.text, tweet.retweeted_status.tweetedUrl] = tweet.retweeted_status.text.split('…');
            }

            return tweet;
          }));

          setLoadStatus('success');
        }
      })
      // Quietly catch errors
      .catch(error => setLoadStatus('error'));

    // Return a function to clean up after the component unmounts.
    return () => isMounted = false;
  }, [])

  return <>

    { loadStatus === 'error' &&
      <div>Problem loading Tweets.</div>
    }

    {tweets.slice(0, props.items).map(tweet => {
      let retweeted_by;
      if (tweet.retweeted_status) {
        retweeted_by = tweet;
        tweet = tweet.retweeted_status;
      }

      return <div key={tweet.id} className='d-flex flex-row mb-4'>

        <img src={tweet.user.profile_image_url_https} style={imageStyle} className='mr-3' alt={tweet.user.name} />

        <div>
          <div style={userInfoStyle} className='mb-1'>
            <strong>{tweet.user.name}</strong><br/>
            <a href={`https://twitter.com/${tweet.user.screen_name}`} target='_blank' rel='noreferrer'
               className='text-muted'>@{tweet.user.screen_name}</a>
            {retweeted_by && <span className='text-muted'> &middot; {retweeted_by.user.name} retweeted</span>}
          </div>
          <div>
            <a href={`https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`} target='_blank' rel='noreferrer'>
              {tweet.text}&hellip;
            </a>
          </div>
        </div>
      </div>
    })}
  </>
}
