import * as React from 'react'
import {Link} from 'gatsby'
import Layout from '../components/layout';
import {LayoutHeadingProps} from '../components/layout-heading';
import SEO from '../components/seo';

const NotFoundPage = () => {

  const heading: LayoutHeadingProps = {
    title: <h1>404 &middot; Not Found</h1>
  };

  return (
    <Layout heading={heading}>
      <SEO
        title='Not Found'
      />
      <p>We couldn't find anything at this address.</p>
      <Link to='/' className='btn btn-primary'>Go to the Homepage</Link>
    </Layout>
  )
}

export default NotFoundPage
