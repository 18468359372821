import * as React from 'react'
import {Component} from 'react'

type InlineSvgProps = {
    svg: any;
    className?: string;
    alt?: string;
    style?: any;
}

// Render inline SVG with fallback non-svg images
class InlineSVG extends Component<InlineSvgProps> {
    render() {
        const { svg, alt, style } = this.props;

        const className = this.props.className ? this.props.className : '';

        if (svg && svg.content) {
            // Inlined SVGs
            return <div className={`inline-svg ${className}`} dangerouslySetInnerHTML={{ __html: svg.content }} title={alt} style={style} />
        } else {
            return null;
        }
    }

}

export default InlineSVG;
