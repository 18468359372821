import * as React from 'react'
import {Component, CSSProperties} from 'react'

type IconProps = {
  name: string;
  className?: string;
  style?: CSSProperties;
}

// Render inline SVG with fallback non-svg images
class Icon extends Component<IconProps> {

  render() {
    const className = `icon-${this.props.name} ${this.props.className}`.trim();
    return <i className={className} style={this.props.style}/>
  }

}

export default Icon;
