import * as React from 'react'
import {Component, CSSProperties} from 'react'
import {urlPresenter} from '../services/url-presenter';
import Icon from './icon';

type IconLinkProps = {
  icon: string;
  className?: string;
  url: string;
  target?: '_blank';
  title?: string;
}

// Render inline SVG with fallback non-svg images
class IconLink extends Component<IconLinkProps> {

  aStyle: CSSProperties = {
    verticalAlign: 'middle'
  }

  iconStyle: CSSProperties = {
    verticalAlign: 'middle',
    fontSize: '1.2rem'
  }

  render() {

    let text;
    let title = this.props.title;

    switch (this.props.icon) {
      case 'github':
        text = 'GitHub';
        break;
      case 'linkedin':
        text = 'LinkedIn';
        break;
      case 'twitter':
        text = this.props.title;
        title = `${this.props.title} on Twitter`;
        break;
      default:
        text = urlPresenter(this.props.url);
    }

    return <a href={this.props.url} rel='noreferrer' style={this.aStyle} target={this.props.target} className={`text-decoration-none ${this.props.className}`} title={title}>
      <Icon name={this.props.icon} className='mr-2' style={this.iconStyle}/>
      <span>{text}</span>
    </a>
  }

}

export default IconLink;
