import * as React from 'react'
import {Component} from 'react'
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image';
import MiniCard from './mini-card';

type PersonCardMiniProps = {
  person: {
    title: string;
    name: string;
    slug?: string;
    photo: {
      gatsbyImageData: IGatsbyImageData
    };
  }
  subtitle?: string;
}

class PersonCardMini extends Component<PersonCardMiniProps> {

  render() {
    
    const {person, subtitle} = this.props;
    const title: string = [person.title, person.name].join(' ').trim();

    return <MiniCard text={title} image={person.photo} subtitle={subtitle} url={`/team/${person.slug}`} />;
  }

}

export default PersonCardMini;
