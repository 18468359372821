import Layout from '../components/layout';
import * as React from 'react';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {Col, Row} from 'react-bootstrap';
import {graphql} from 'gatsby';
import InlineSVG from '../components/inline-svg';
import PersonCardMini from '../components/person-card-mini';
import SEO from '../components/seo';
import documentToReactOptions, {sidebarSection} from '../shared-options';
import {LayoutHeadingProps} from '../components/layout-heading';

const ProjectTemplate = ({ data, pageContext, location }) => {

    const funderLogoStyle = {
        maxHeight: '8rem'
    }

    const project = data.contentfulProject;

    const iconLinks = [];

    if (project.websiteUrl) { iconLinks.push({
        icon: 'home',
        url: project.websiteUrl,
        title: 'Homepage'
    }); }

    if (project.githubUrl) { iconLinks.push({
        icon: 'github',
        url: project.githubUrl
    }); }

    const heading: LayoutHeadingProps = {
        title: <h1>{project.name}</h1>,
        image: <InlineSVG svg={project.logo.svg} className={`project-image`} alt={`${project.name} logo`}/>,
        text: project.shortDescription,
        links: iconLinks
    };

    return (<>
        <Layout heading={heading}>

            <SEO
              title={project.name}
              description={project.shortDescription}
            />

            <Row>
                <Col md={{order: 2, span: 4}} className='mb-3'>
                    <Row xs={1} sm={1} md={1}>
                        <Col>
                            <h2 className='h4 text-primary'>Investigators</h2>

                            <Row className={sidebarSection.margin.top} xs={1} sm={2} md={1}>
                                { project.principalInvestigator &&
                                    <Col>
                                        <PersonCardMini person={project.principalInvestigator} subtitle='Principal Investigator'/>
                                    </Col>
                                }

                                { project.investigators?.length > 0 &&
                                    project.investigators.map(investigator =>
                                      <Col key={investigator.slug}>
                                          <PersonCardMini person={investigator}/>
                                      </Col>
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>

                </Col>
                <Col>
                    {documentToReactComponents(JSON.parse(project.longDescription.raw), documentToReactOptions)}

                    { project.funders?.length > 0 &&
                    <>
                        <h2 className='h4 text-primary mb-3'>Project originally funded by</h2>
                        { project.funders.map(funder =>
                          <Row className='mb-4 justify-content-center' key={funder.id}>
                              <Col xs={8} sm={6} md={5} className='mt-2 mb-3 text-center'>
                                  <InlineSVG svg={funder.logo.svg} alt={funder.name} style={funderLogoStyle} className='project-funder-logo'/>
                              </Col>
                              <Col xs={12} sm={6} md={7}>
                                  <h3 className='sr-only'>{funder.name}</h3>
                                  {funder.description}
                              </Col>
                          </Row>
                        )}
                    </>
                    }
                </Col>
            </Row>
        </Layout>
    </>);
}

export default ProjectTemplate;

export const query = graphql`
    query ProjectBySlug($slug: String!) {
        contentfulProject(slug: {eq: $slug}) {
            name
            slug
            startDate
            endDate
            websiteUrl
            githubUrl
            shortDescription
            longDescription {
                raw
            }
            funders {
                id
                name
                logo {
                    svg {
                        absolutePath
                        content
                        dataURI
                        originalContent
                        relativePath
                    }
                }
                description
            }
            logo {
                svg {
                    absolutePath
                    content
                    dataURI
                    originalContent
                    relativePath
                }
            }
            investigators {
                name
                title
                slug
                photo {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            principalInvestigator {
                name
                title
                slug
                photo {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    }
`
