import * as React from 'react'
import {Helmet} from 'react-helmet'
import Layout from '../components/layout';
import SEO from '../components/seo';
import {graphql, Link} from 'gatsby';
import {Button, Col, Row} from 'react-bootstrap';
import InlineSVG from '../components/inline-svg';
import {LayoutHeadingProps} from '../components/layout-heading';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import documentToReactOptions from '../shared-options';
import BlogFeed from '../components/blog-feed';
import TwitterFeed from '../components/twitter-feed';

// markup
const IndexPage = ({data}) => {

  const page = data.page;
  const logo = data.logo;
  const projects = data.showcase.projects;

  const content = documentToReactComponents(JSON.parse(page.content.raw), documentToReactOptions)

  const heading: LayoutHeadingProps = {
    title: <h1 className='h3'>{page.title}</h1>,
    text: content,
    image: <InlineSVG svg={logo.svg} className={`home-header-image d-none d-sm-block`} alt='ECS Accessibility Team Logo'/>
  }

  return (
      <Layout heading={heading}>
        <Helmet>
          <html className='overflow-y'/>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://access.ecs.soton.ac.uk",
                "name": "ECS Accessibility Team"
              }
            `}
          </script>
        </Helmet>

        <SEO title={null} description={page.metaDescription} />

        <Row className='mt-5'>
          <Col md={{span: 6, offset: 3}}>
            <h2 className='text-center text-primary'>Explore our Projects</h2>
          </Col>
        </Row>

        <Row xs={2} sm={3} className='home-projects mt-4 align-items-center'>
          {projects.map(project =>
            <Col className='my-4 px-4 text-center' key={project.slug}>
              <Link to={`projects/${project.slug}`} title={project.name}>
                <InlineSVG svg={project.logo.svg}/>
              </Link>
            </Col>
          )}
        </Row>
        <div className='mb-5 mt-3 text-center'>
          <Link to='projects'>
            <Button as="span" variant='primary'>All Projects</Button>
          </Link>
        </div>

        <Row xs={1} sm={2} className='mt-5'>
          <Col className='d-flex flex-column'>
            <h2 className='text-primary mb-4'>Tweets</h2>
            <TwitterFeed items={4}/>
          </Col>
          <Col>
            <h2 className='text-primary'>News</h2>
            <BlogFeed items={3}/>
          </Col>
        </Row>
    </Layout>
  )
};

export default IndexPage;

export const query = graphql`
    {
        
        page: contentfulPage(slug: {eq: "home"}) {
            title
            content {
                raw
            }
            metaDescription
        }
        
        showcase: contentfulShowcase(slug: {eq: "homepage-projects"}) {
            projects {
                id
                slug
                name
                shortDescription
                logo {
                    svg {
                        content # SVG content optimized with SVGO
                        originalContent # Original SVG content
                        dataURI # Optimized SVG as compact dataURI
                        absolutePath #
                        relativePath #
                    }
                }
            }
        }
        logo: contentfulAsset(contentful_id: {eq: "01x0qIC1KSj8kKEXB6byYF"}) {
            svg {
                absolutePath
                dataURI
                content
                relativePath
            }
            file {
                url
            }
        }
    }
`
