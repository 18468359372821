import * as React from 'react'
import {Component, CSSProperties} from 'react'
import {Helmet} from 'react-helmet'

type ATBarButtonState = {
  showATBar: boolean;
}

class ATBarButton extends Component<{}, ATBarButtonState> {

  constructor(props) {
    super(props);
    this.state = {showATBar: false};
    this.loadToolbar = this.loadToolbar.bind(this);
  }

  loadToolbar(): void {

    // If ATBar has been loaded previously, run it directly.
    // We have to ts-ignore the __start
    // @ts-ignore
    if (this.state.showATBar && __start) { __start(); } else {
      // Otherwise, inject the ATBar script.
      this.setState({
        showATBar: true
      });
    }
  }

  render() {
    return (<>
      <Helmet>
        {this.state.showATBar === true &&
          <script src='https://core.atbar.org/atbar/en/latest/atbar.min.js' type='text/javascript' id='ToolBar'></script>
        }
      </Helmet>
      <button className="btn btn-outline-light my-2 my-sm-0 btn-atbar" id='toolbar-launch'
              onClick={this.loadToolbar}
              title='Launch ATbar to adjust this webpage, have it read aloud and other functions.'
      >
        <span>AT</span>bar
      </button>
    </>)
  }
}

export default ATBarButton;
