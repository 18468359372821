// markup
import Layout from '../components/layout';
import * as React from 'react';
import {graphql, Link} from 'gatsby';
import {Card, Col, Container, Row} from 'react-bootstrap';
import InlineSVG from '../components/inline-svg';
import SEO from '../components/seo';
import {LayoutHeadingProps} from '../components/layout-heading';

const ProjectsPage = ({data}) => {

    const projects = data.projects.nodes;

    const heading: LayoutHeadingProps = {
        title: <h1>Projects</h1>
    };

    return (
        <Layout heading={heading} container={false}>

            <SEO
              title='Projects'
              description='Explore our projects, including Lexdis, Synote, Web2Access, ATbar and more.'
            />

            <Container fluid='xl' className='px-xl-0'>
                <Row xs={1} md={2}>
                    {projects.map(project =>
                        <Col className='mb-4' key={project.id}>
                            <Link to={project.slug} className='aae-card-link'>
                                <Card className='h-100 border-0 bg-transparent project-card'>
                                    <Card.Body className='d-flex flex-column'>
                                        <Card.Title className='aae-action-arrow text-decoration-none d-block'>
                                            {project.name}
                                        </Card.Title>
                                        <Row>
                                            <Col>
                                                <Card.Text className='text-decoration-none'>
                                                    {project.shortDescription}
                                                </Card.Text>
                                            </Col>
                                            <Col xs={5}>
                                                <InlineSVG svg={project.logo.svg}/>
                                                {/*<Img fluid={project.logo} alt={project.name} />*/}
                                            </Col>
                                        </Row>


                                        {/*<Link className='mt-auto' to={project.slug}>*/}
                                        {/*    <Button className='mt-3' as="span" block>Read More</Button>*/}
                                        {/*</Link>*/}
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                    )}
                </Row>
            </Container>
        </Layout>
    );
}

export default ProjectsPage;

export const query = graphql`
    {
        projects: allContentfulProject(sort: {fields: startDate, order: ASC}) {
            nodes {
                id
                slug
                name
                shortDescription
                logo {
                    svg {
                        content # SVG content optimized with SVGO
                        originalContent # Original SVG content
                        dataURI # Optimized SVG as compact dataURI
                        absolutePath #
                        relativePath #
                    }
                }
            }
        }
    }
`
