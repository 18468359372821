import * as React from 'react';
import {Options} from '@contentful/rich-text-react-renderer';

const documentToReactOptions: Options = {
  // Inject a <br/> wherever a \n linebreak is found in the source.
  // renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
  renderText: text => text.split('\n').map((text, i) => [i > 0 && <br key={i} />, text])
}

export const containerPadding = 'px-0 px-sm-3';

export const sidebarSection = {
  margin: {
    top: 'mt-4',
    bottom: 'mb-4',
  }
}

export const sidebarItem = {
  margin: {
    top: 'mt-2',
    bottom: 'mb-2',
  }
}

export default documentToReactOptions;
