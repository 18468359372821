import Layout from '../components/layout';
import * as React from 'react';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import SEO from '../components/seo';
import {Col, Row} from 'react-bootstrap';
import { GatsbyImage } from "gatsby-plugin-image";
import {graphql} from 'gatsby';
import documentToReactOptions from '../shared-options';
import {LayoutHeadingProps} from '../components/layout-heading';

const PageTemplate = ({ data, pageContext, location }) => {

    const page = data.contentfulPage;

    const content = documentToReactComponents(JSON.parse(page.content.raw), documentToReactOptions);

    const heading: LayoutHeadingProps = {
        title: <h1>{page.title}</h1>
    };

    return <>
        <Layout heading={heading}>

            <SEO title={page.title} description={page.metaDescription} />

            <Row>
                <Col>{content}</Col>
                {page.mainImage?.gatsbyImageData &&
                  <Col md={4}>
                      <GatsbyImage image={page.mainImage.gatsbyImageData} alt={page.mainImage.title} />
                  </Col>

                }
            </Row>
        </Layout>
    </>;
}

export default PageTemplate;

export const query = graphql`
    query PageBySlug($slug: String!) {
        contentfulPage(slug: {eq: $slug}) {
            title
            slug
            metaDescription
            content {
                raw
            }
            mainImage {
                title
                description
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
`
