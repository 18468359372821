import * as React from 'react';
import {useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';

type LoadStatus = 'loading' | 'success' | 'error';

type BlogFeedProps = {
  items: number;
}

type Post = {
  guid: string;
  title: string;
  url: string;
  date: Date;
  text: string;
}

type PostSource = {
  url: string;
  fetched: boolean;
}

export default function BlogFeed(props: BlogFeedProps) {

  // Fetch the latest 3 posts from access.ecs.soton.ac.uk/blog
  // and the latest 3 posts in the news category from Lexdis.
  const sources: PostSource[] = [
    { fetched: false, url: 'https://access.ecs.soton.ac.uk/blog/accessiblog/wp-json/wp/v2/posts?per_page=3' },
    { fetched: false, url: 'https://www.lexdis.org.uk/wp-json/wp/v2/posts?per_page=3&categories=439' }
  ];

  // Temporary variable for assembling and sorting Posts from multiple sources
  const postsRepo: Post[] = Array<Post>();

  const [posts, setPosts] = useState(new Array<Post>());
  const [loadStatus, setLoadStatus] = useState<LoadStatus>('loading');

  let isMounted = true;

  useEffect(() => {

    // Fetch each provided URL, convert the response to JSON and push new Posts to the postsRepo.
    sources.forEach(source =>
      fetch(source.url)
      .then(response => response.json())
      .then(sourcePosts => {
        // console.log(posts);
        sourcePosts.forEach(post => {
          postsRepo.push({
            guid: post.guid.rendered,
            title: post.title.rendered,
            url: post.link,
            date: new Date(post.date),
            text: post.excerpt.rendered
          })
        });

        source.fetched = true;

        // If the component is still mounted AND all blog sources have loaded, present them.
        if (isMounted && sources.find(source => source.fetched === false) === undefined) {
          console.log('sorting posts!', source.url);
          console.log(postsRepo);
          // Update the view with all Posts, sorted by time
          setPosts(postsRepo.sort((a, b) => b.date.getTime() - a.date.getTime()));
          setLoadStatus('success');
        }
      })
      // Quietly catch errors
      .catch(error => setLoadStatus('error'))
    );

    // Return a function to clean up after the component unmounts.
    return () => isMounted = false;
  }, [])

  return <>

    { loadStatus === 'error' &&
      <div>Problem loading Blog Posts.</div>
    }

    {posts.slice(0, props.items).map(post =>
      <a href={post.url} className='aae-card-link aae-post-card' key={post.guid}>
        <Card className='border-0 bg-transparent'>
          <Card.Body className='px-0'>
            <Card.Title className='aae-action-arrow text-decoration-none d-block'
                        dangerouslySetInnerHTML={{__html: post.title}}/>
            <div className='mb-2'>{post.date.toLocaleDateString()}</div>
            <Card.Text className='text-decoration-none' as='div' dangerouslySetInnerHTML={{__html: post.text}}/>
          </Card.Body>
        </Card>
      </a>
    )}
  </>
}
