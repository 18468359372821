import * as React from 'react';
import '../styles/main.scss';
import {Container, Nav, Navbar} from 'react-bootstrap';
import {graphql, Link, useStaticQuery} from 'gatsby';
import LayoutHeading, {LayoutHeadingProps} from './layout-heading';
import ConditionalWrapper from './conditional-wrapper';
import InlineSVG from './inline-svg';
import {Helmet} from 'react-helmet';
import ATBarButton from './atbar-button';

type LayoutProps = {
    children: any;
    container?: boolean;
    heading?: LayoutHeadingProps
}

export default function Layout({children, heading, container}: LayoutProps) {

    const {uniLogo, logo} = useStaticQuery(query);

    return (<>

        <Helmet>
            <body className='bg-secondary'/>
        </Helmet>

        <Navbar variant='dark' bg="primary" expand="sm" className='mb-3' role='navigation' aria-label="Main">
            <Container fluid='xl'>
                <Link to="/" className="navbar-brand">
                    <InlineSVG svg={logo.svg} className='d-inline-block logo align-top mr-3'/>
                    {/*<img src={logo.file.url} className='d-inline-block logo align-top mr-3'/>*/}
                    ECS Accessibility Team
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Link className='nav-link' activeClassName='active' partiallyActive={true} to="/projects">Projects</Link>
                        <Link className='nav-link' activeClassName='active' partiallyActive={true} to="/team">Team</Link>
                        <Link className='nav-link' activeClassName='active' partiallyActive={true} to="/consultancy">Consultancy</Link>
                        <Link className='nav-link' activeClassName='active' partiallyActive={true} to="/contact">Contact</Link>
                    </Nav>
                    <div className="my-2 my-lg-0">
                        <ATBarButton/>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>

        <main className='bg-white'>

            { heading &&
                <div className='bg-secondary text-primary mt-n3 px-0 px-sm-3'>
                    <Container fluid='xl'>
                        <LayoutHeading title={heading.title}
                                       image={heading.image}
                                       text={heading.text}
                                       links={heading.links}

                        />
                    </Container>
                </div>
            }

            <ConditionalWrapper
              condition={container !== false}
              wrapper={children =>
                <div className='px-0 px-sm-3'>
                    <Container fluid='xl'>
                        {children}
                    </Container>
                </div>
              }
            >{children}</ConditionalWrapper>

            <footer>
                <Navbar variant='dark' bg="primary" expand="sm" className='py-3 mt-5 text-white' aria-label="Footer">
                    <Container fluid='xl' className='flex-column'>

                        <div className='mb-4'>
                            &copy; ECS Accessibility Team
                            &nbsp;&middot;&nbsp;
                            <Link to="/accessibility" className='text-light mx-1'>Accessibility</Link>
                            &nbsp;&middot;&nbsp;
                            <Link to="/privacy" className='text-light mx-1'>Privacy</Link>
                        </div>

                        <div>
                            <a href='https://southampton.ac.uk' rel='noreferrer' title='University of Southampton'>
                                <InlineSVG svg={uniLogo.svg} className='d-inline-block uni-logo'/>
                            </a>
                        </div>

                    </Container>
                </Navbar>
            </footer>
        </main>
    </>)
}

const query = graphql`
    query {
        logo: contentfulAsset(contentful_id: {eq: "7sfi74DzngPfOKddPe2mz3"}) {
            svg {
                absolutePath
                dataURI
                content
                relativePath
            }
            file {
                url
            }
        },
        uniLogo: contentfulAsset(contentful_id: {eq: "5fx6HRVjq6PJj539meo41A"}) {
            svg {
                absolutePath
                dataURI
                content
                relativePath
            }
            file {
                url
            }
        }
    }
`
