import * as React from 'react'
import {Component} from 'react'
import {Link} from 'gatsby';
import {Badge} from 'react-bootstrap';
import {GatsbyImage, IGatsbyImageData} from 'gatsby-plugin-image';
import InlineSVG from './inline-svg';

interface MiniCardProps {
  text: string;
  url: string;
  subtitle?: string;
  image: {
    file?: {
      url: string;
    };
    svg?: any;
    gatsbyImageData: IGatsbyImageData
  }
}

class MiniCard extends Component<MiniCardProps> {

  imageStyle = {
    width: 50,
    height: 50
  }

  image() {

    const {image, text} = this.props;

    if (image?.gatsbyImageData) {
      return <GatsbyImage image={image.gatsbyImageData} alt={text} style={this.imageStyle} />;

    } else if (image?.file) {
      return <img src={image.file.url} alt={text} style={this.imageStyle} className='d-flex align-items-center'/>;

    } else if (image?.svg) {
      return <InlineSVG svg={image.svg} alt={text} style={this.imageStyle} className='d-flex align-items-center'/>;
    }
  }

  render() {

    const {text, url, subtitle} = this.props;

    return <Link to={url} className='d-flex align-items-center mb-3 text-decoration-none'>
      <div style={this.imageStyle} className='mr-3'>
        {this.image()}
      </div>
      <div className='flex-column'>
        <div>{text}</div>
        {subtitle &&
        <Badge className='text-decoration-none text-uppercase' variant='secondary'>{subtitle}</Badge>
        }

      </div>
    </Link>
  }

}

export default MiniCard;
