import * as React from 'react';
import '../styles/main.scss';
import {Col, Row} from 'react-bootstrap';
import IconLink from './icon-link';
import InlineSVG from './inline-svg';

export type LayoutHeadingProps = {
  title?: any;
  image?: any;
  text?: any;
  links?: Array<{
    icon: string;
    url: string;
    title?: string;
  }>
}

export default function LayoutHeading(props: LayoutHeadingProps) {
  return (
    <Row className='justify-content-center justify-content-sm-between align-items-center py-4 mb-4'>
      <Col xs={12} sm={8} md={6}>
        { props.title && props.title }
        { props.text && <div className='my-4'>{props.text}</div> }

        { props.links?.length > 0 && <div className='mt-4'>
          {props.links?.map((link, index) =>
            <IconLink
              key={index}
              icon={link.icon}
              url={link.url}
              title={link.title}
              target='_blank'
              className='mr-4'/>)
          }
        </div>}

      </Col>
      <Col xs={8} sm={4} md={4}>
        {props.image && <div className='mt-4 mb-sm-0'>{props.image}</div>}
      </Col>
    </Row>
  );
}
