import Layout from '../components/layout';
import * as React from 'react';
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import {Col, Row} from 'react-bootstrap';
import {graphql} from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from '../components/seo';
import documentToReactOptions, {sidebarSection} from '../shared-options';
import MiniCard from '../components/mini-card';
import {LayoutHeadingProps} from '../components/layout-heading';

const PersonTemplate = ({ data, pageContext, location }) => {

    const person = data.person;
    const projects = data.projects.nodes;
    const projectsAsPI = data.projectsAsPI.nodes;

    const title: string = [person.title, person.name].join(' ').trim();

    const heading: LayoutHeadingProps = {
        title: <h1>{title}</h1>,
        links: []
    }

    if (person.websiteUrl) { heading.links.push({
        icon: 'home',
        url: person.websiteUrl
    }); }

    if (person.linkedinUrl) { heading.links.push({
        icon: 'linkedin',
        url: person.linkedinUrl
    }); }

    if (person.twitterName) { heading.links.push({
        icon: 'twitter',
        url: `https://twitter.com/${person.twitterName}`,
        title: person.twitterName
    }); }

    return <>
        <Layout heading={heading}>

            <SEO
              title={title}
              description={person.shortBio}
            />

            <Row>
                <Col>
                    {person.photo?.gatsbyImageData &&
                        <div className='float-right d-sm-none ml-3 mb-2' style={{width: '50%'}}>
                            <GatsbyImage
                                image={person.photo.gatsbyImageData}
                                alt={title}
                                className={sidebarSection.margin.bottom} />
                        </div>
                    }

                    {person.shortBio &&
                        <p className='lead'>{person.shortBio}</p>
                    }

                    {person.longBio?.raw &&
                        documentToReactComponents(JSON.parse(person.longBio?.raw), documentToReactOptions)
                    }
                </Col>
                <Col sm={6} md={4}>
                    {person.photo?.gatsbyImageData &&
                        <GatsbyImage
                            image={person.photo.gatsbyImageData}
                            alt={title}
                            className={`${sidebarSection.margin.bottom} d-none d-sm-block`} />
                    }


                    <h2 className={`${sidebarSection.margin.top} h4 text-primary`}>Projects</h2>

                    { projectsAsPI.map(project =>
                      <MiniCard text={project.name} image={project.logo} url={`/projects/${project.slug}`} key={project.slug}/>
                    )}

                    { projects.map(project =>
                      <MiniCard text={project.name} image={project.logo} url={`/projects/${project.slug}`} key={project.slug}/>
                    )}
                </Col>
            </Row>
        </Layout>
    </>;
}

export default PersonTemplate;

export const query = graphql`
    query PersonBySlug($slug: String!) {
        person: contentfulPerson(slug: {eq: $slug}) {
            title
            name
            slug
            rank {
                name
            }
            websiteUrl
            linkedinUrl
            twitterName
            shortBio
            longBio {
                raw
            }
            projects: project {
                name
                slug
            }
            photo {
                gatsbyImageData(layout: FULL_WIDTH)
            }
        }

        projectsAsPI: allContentfulProject(
            filter: {principalInvestigator: {slug: {eq: $slug}}}, sort: {fields: name}) {
            nodes {
                name
                slug
                logo {
                    file {
                        url
                    }
                    svg {
                        content # SVG content optimized with SVGO
                        originalContent # Original SVG content
                        dataURI # Optimized SVG as compact dataURI
                        absolutePath #
                        relativePath #
                    }
                }
            }
        }

        projects: allContentfulProject(
            filter: {investigators: {elemMatch: {slug: {eq: $slug}}}}, sort: {fields: name}) {
            nodes {
                name
                slug
                logo {
                    file {
                        url
                    }
                    svg {
                        content # SVG content optimized with SVGO
                        originalContent # Original SVG content
                        dataURI # Optimized SVG as compact dataURI
                        absolutePath #
                        relativePath #
                    }
                }
            }
        }
    }
`
