
// Takes a full URL and removes the http(s):// and www. prefixes, if present.
export function urlPresenter(inputUrl: string): string {
  // Build a URL object
  const url = new URL(inputUrl);

  // Chop off www. from the start of the hostname, if present.
  url.hostname = url.hostname.replace(/^(www\.)/,"");

  // Output just the hostname and path.
  return `${url.hostname}${url.pathname}`.replace(/\/$/, "");
}
